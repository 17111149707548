import useHttp from "Hooks/useHttp";
import SwitchField from "components/fields/SwitchField";
import React, { useEffect, useState } from "react";
import routes from "routes";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Loader from "components/Loader";
const ControlPanel = () => {
  const API = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const { PostRequest } = useHttp();
  const Data = sessionStorage.getItem("user");
  const [LoginUser] = useState(JSON.parse(Data));
  const [loading, setLoading] = useState(false);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [controlId, setControlId] = useState(false);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  // const [CurrentPath, setCurrentPath] = React.useState("Main Dashboard");
  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getControlPanels = async () => {
    if (LoginUser?.AccountStatus === 3 || LoginUser?.isSuperAdmin == 0) {
      toast.error("Access denied! Please contact Admin.");
      return false;
    }
    setLoading(true);
    const { data } = await PostRequest(
      API + "control/getControlPanels",
      {},
      {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }
    );
    if (data?.responseCode === 1) {
      setControlId(data?.responseData?._id);
      setIsMaintenanceMode(
        data?.responseData?.maintenanceMode == 1 ? true : false
      );
    } else {
      toast.error(data?.responseText);
    }
    setLoading(false);
  };
  const updateControl = async () => {
    if (LoginUser?.AccountStatus === 3 || LoginUser?.isSuperAdmin == 0) {
      toast.error("Access denied! Please contact Admin.");
      return false;
    }
    setLoading(true);
    const { data } = await PostRequest(
      API + "control/updateControl",
      { maintenanceMode: !isMaintenanceMode ? 1 : 0, id: controlId },
      {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }
    );
    if (data.responseCode === 1) {
      console.log(data.responseData.maintenanceMode);
      setIsMaintenanceMode(
        data.responseData.maintenanceMode == 1 ? true : false
      );
    } else {
      toast.error(data.responseText);
    }
    setLoading(false);
  };

  useEffect(() => {
    getControlPanels();
  }, [isMaintenanceMode]);

  return (
    <div className="flex">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="w-99p flex-initial md:w-50p">
        <div className="mb-2 flex items-center justify-center rounded-xl bg-[#30A5FF] px-4 py-4  sm:px-6">
          <h1 className="text-2xl font-black text-white">{currentRoute}</h1>
        </div>
        <div className="rounded-xl bg-white px-4 py-3  sm:px-6">
          <SwitchField
            id="MaintenanceMode"
            label="Maintenance Mode"
            dec="Enable Maintenance Mode"
            mb="mb-5"
            checked={isMaintenanceMode}
            onChange={updateControl}
          />
          <div className="flex w-full justify-between">
            <h1 className="text-base font-bold text-navy-700 dark:text-white">
              Logout All User
            </h1>
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            >
              <i className="fas fa-refresh display-4 gap-1">
                &nbsp;&nbsp;Logout
              </i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlPanel;
