/* eslint eqeqeq: 0 */
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import MaintenanceMode from "layouts/auth/MaintenanceMode";
import Home from "components/Home/Home";
import NotFound from "components/NotFound";
const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<AuthLayout />} />
        {/* {isMaintenanceMode ?  <Route path="/MaintenanceMode" element={<MaintenanceMode />} /> : } */}
        <Route path="/MaintenanceMode" element={<MaintenanceMode />} />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="/" element={<Navigate to="/admin" replace />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
