import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
export default function DataTable({ columnsData, tableData, pageSize }) {
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        rows={tableData}
        columns={columnsData}
        getRowId={(row) => row._id || row._id}
        // on
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: pageSize },
          },
        }}
        pageSizeOptions={[5, 10]}
      />
    </div>
  );
}
