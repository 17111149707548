import React, { useEffect, useState } from "react";
import useHttp from "Hooks/useHttp";
import { Link } from "react-router-dom";
const API = process.env.REACT_APP_API_URL;
const HomePage = () => {
  const { PostRequest } = useHttp();
  const [files, setFiles] = useState([]);
  const DownloadContent = async () => {
    const { data } = await PostRequest(API + "users/DownloadContent", {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    });
    if (data.responseCode === 1) {
      setFiles(data.responseData);
    }
  };

  useEffect(() => {
    DownloadContent();
  }, []);
  return (
    <div
      className="flex items-center justify-center bg-cover bg-center bg-no-repeat sm:h-screen"
      style={{ backgroundImage: `url('../assets/bg-new.jpg')` }}
    >
      <div
        id="wrapper"
        className="mx-auto mt-0 w-full px-4 sm:w-[747px] sm:px-0"
      >
        <div className="Flash_Logo float-left mt-2.5 h-[80px] w-[127px] sm:h-[160px] sm:w-[254px]">
          <img
            src="../assets/logo.png"
            alt="KJ Logo"
            className="h-full w-auto"
          />
        </div>
        <div className="Web_InstructionBanner relative top-[-10px] h-auto w-full sm:h-[430px] sm:w-[780px]">
          <img
            src="../assets/Web_InstructionBanner.png"
            alt="Web Instruction Banner"
            className="h-auto w-full sm:h-[408px]"
          />
        </div>
        <div className="Buttons relative mt-3.5 flex flex-col justify-center sm:left-[10px] sm:flex-row">
          {files.map((file, index) => (
            <a
              key={index}
              download
              href={`${API}${file.file.filepath}`}
              className="mx-2 mb-2 inline-block sm:mb-0"
            >
              {file.file.type === "PC" && (
                <img
                  src="../assets/btn-download-nor1.png"
                  alt="Download Button"
                  className="h-[67px] w-[140px] transition duration-300 hover:opacity-80 sm:h-[100px] sm:w-[210px]"
                />
              )}
              {file.file.type === "MOBILE" && (
                <img
                  src="../assets/btn-instant-nor1.png"
                  alt="Download Button"
                  className="h-[67px] w-[140px] transition duration-300 hover:opacity-80 sm:h-[100px] sm:w-[210px]"
                />
              )}
              {file.file.type === "PCPRINT" && (
                <img
                  src="../assets/download-v2.png"
                  alt="Download Button"
                  className="h-[100px] w-[140px] transition duration-300 hover:opacity-80 sm:h-[80px] sm:w-[210px]"
                />
              )}
            </a>
          ))}
        </div>
        <div className="banner18 mx-auto mt-4 w-[240px] pt-0 sm:w-[373px]">
          <img
            src="../assets/Web_18+banner.png"
            alt="18+ Banner"
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
