import React from "react";
// Admin Imports
import DataTables from "views/admin/tables/DataTables";
// auth routes
import UpdateBalance from "components/UpdateBalance/UpdateBalance";
import ChangePassword from "components/ChangePassword/ChangePassword";
import TurnoverReportTables from "views/admin/tables/TurnoverReportTables";
import PlayerHistoryTables from "views/admin/tables/PlayerHistoryTables";
import GameHistoryTables from "views/admin/tables/GameHistoryTables";
import TransactionReportTables from "views/admin/tables/TransactionReportTables";
import IDChangePassword from "components/IDChangePassword/IDChangePassword";
import ControlPanel from "components/ControlPanel/ControlPanel";
import SetPower from "components/SetPower/SetPower";
import AdjustPoint from "components/AdjustPoint/AdjustPoint";
import ProfileOverview from "views/admin/profile";
import WinGame from "components/WinGame/WinGame";
import Dashboard from "views/admin/default";
import UpdateFiles from "components/UpdateFiles/UpdateFiles";
import PlayerSecurity from "./views/admin/tables/components/PlayerSecurity";
import GraphComponent from "./components/Graph";

const routes = [
  {
    name: <h4 className="text-white">Dashboard</h4>,
    layout: "/admin",
    icon: <img src="../assets/s1.png" alt="menu" className="h-6 w-6" />,
    path: "dashboard",
    component: <Dashboard />,
    type: 0,
  },
  {
    name: <h4 className="text-white">View Admin</h4>,
    layout: "/admin",
    icon: <img src="../assets/s1.png" alt="menu" className="h-6 w-6 " />,
    path: "admin",
    component: <DataTables />,
    type: 4,
  },
  {
    name: <h4 className="text-white">View Stokez</h4>,
    layout: "/admin",
    icon: <img src="../assets/s1.png" alt="menu" className="h-6 w-6 " />,
    path: "Stokez",
    component: <DataTables />,
    type: 1,
  },
  {
    name: <h4 className="text-white">View Agents</h4>,
    layout: "/admin",
    icon: <img src="../assets/s2.png" alt="menu" className="h-6 w-6" />,
    path: "Agent",
    component: <DataTables />,
    type: 2,
  },
  {
    name: <h4 className="text-white">View Player</h4>,
    layout: "/admin",
    icon: <img src="../assets/s3.png" alt="menu" className="h-6 w-6" />,
    path: "Player",
    component: <DataTables />,
    type: 3,
  },
  {
    name: <h4 className="text-white">Win Game</h4>,
    layout: "/admin",
    icon: <img src="../assets/win.jpg" alt="menu" className="h-6 w-6" />,
    path: "win-game",
    component: <WinGame />,
    type: 1,
  },
  {
    name: <h4 className="text-white">Transfer Points</h4>,
    layout: "/admin",
    icon: <img src="../assets/s4.png" alt="menu" className="h-6 w-6" />,
    path: "transfer-points",
    component: <UpdateBalance />,
    type: 3,
  },
  {
    name: <h4 className="text-white">Set Power</h4>,
    layout: "/admin",
    icon: <img src="../assets/s1.png" alt="menu" className="h-6 w-6" />,
    path: "set-power",
    component: <SetPower />,
    type: 1,
  },
  {
    name: <h4 className="text-white">Adjust Point</h4>,
    layout: "/admin",
    icon: <img src="../assets/s5.png" alt="menu" className="h-6 w-6" />,
    path: "adjust-point",
    component: <AdjustPoint />,
    type: 3,
  },
  {
    name: <h4 className="text-white">Change Password</h4>,
    layout: "/admin",
    icon: <img src="../assets/s6.png" alt="menu" className="h-6 w-6" />,
    path: "change-password",
    component: <ChangePassword />,
    type: 3,
  },
  {
    name: <h4 className="text-white">Turnover Report</h4>,
    layout: "/admin",
    icon: <img src="../assets/s7.png" alt="menu" className="h-6 w-6" />,
    path: "turnover-report",
    component: <TurnoverReportTables />,
    type: 3,
  },
  {
    name: <h4 className="text-white">Player History</h4>,
    layout: "/admin",
    icon: <img src="../assets/s8.png" alt="menu" className="h-6 w-6" />,
    path: "player-history",
    component: <PlayerHistoryTables />,
    type: 3,
  },
  {
    name: <h4 className="text-white">Transaction History</h4>,
    layout: "/admin",
    icon: <img src="../assets/s9.png" alt="menu" className="h-6 w-6" />,
    path: "transaction-history",
    component: <TransactionReportTables />,
    type: 3,
  },
  {
    name: <h4 className="text-white">Game History</h4>,
    layout: "/admin",
    icon: <img src="../assets/s10.png" alt="menu" className="h-6 w-6" />,
    path: "game-history",
    component: <GameHistoryTables />,
    type: 3,
  },
  {
    name: <h4 className="text-white">ID Password Change</h4>,
    layout: "/admin",
    icon: <img src="../assets/s6.png" alt="menu" className="h-6 w-6" />,
    path: "id-password-change",
    component: <IDChangePassword />,
    type: 3,
  },
  {
    name: <h4 className="text-white">Control Panel</h4>,
    layout: "/admin",
    icon: <img src="../assets/s6.png" alt="menu" className="h-6 w-6" />,
    path: "control-panel",
    component: <ControlPanel />,
    type: 4,
  },
  {
    name: <h4 className="text-white">Profile</h4>,
    layout: "/admin",
    path: "profile",
    icon: <img src="../assets/s1.png" alt="menu" className="h-6 w-6" />,
    component: <ProfileOverview />,
    type: 3,
  },
  {
    name: <h4 className="text-white">Upload Game</h4>,
    layout: "/admin",
    icon: <img src="../assets/win.jpg" alt="menu" className="h-6 w-6" />,
    path: "upload-game",
    component: <UpdateFiles />,
    type: 1,
  },
  /*{
     name: "logout",
     layout: "/admin",
     path: "logout",
     icon: <img src="../assets/s1.png" alt="menu" className="h-6 w-6" />,
     component: <Logout />,
   },
*/
  {
    name: <h4 className="text-white">Security</h4>,
    layout: "/admin",
    icon: <img src="../assets/s11.png" alt="menu" className="h-6 w-6" />,
    path: "security",
    component: <PlayerSecurity />,
    type: 0,
  },
  {
    name: <h4 className="hidden h-0 w-0 text-white">Graph</h4>,
    layout: "/admin",
    icon: <img src="../assets/s5.png" alt="menu" className="hidden h-0 w-0" />,
    path: "graph",
    component: <GraphComponent />,
    type: 2,
  },
];
export default routes;
