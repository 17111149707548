import React from "react";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import useHttp from "../../../Hooks/useHttp";
import SelectField from "components/fields/SelectField";
import Pagination from "components/Pagination/Pagination";
import Loader from "components/Loader";
import InputField from "components/fields/InputField";
import PageNameBar from "components/PageNameBar/PageNameBar";
import DevelopmentTable from "./components/DevelopmentTable";
import {
  columnsDataDevelopment,
  columnsDataUsers,
} from "./variables/columnsData";

const Tables = () => {
  const { PostRequest } = useHttp();
  const location = useLocation();
  const isFromDashboard = location.state && location.state.fromDashboard;

  const API = process.env.REACT_APP_API_URL;
  const [TableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [TotalPage, setTotalPage] = useState(0);
  const [CallApi, setCallApi] = useState(false);
  const Data = sessionStorage.getItem("user");
  const [LoginUser] = useState(JSON.parse(Data));
  const [pageSize, setPageSize] = useState(10);
  const [userType, setUserType] = useState("");
  const [ParentId, setParentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("All"); // Add state for filter

  const isUpdatedTodayInIST = (updatedTime) => {
    const updatedDateObj = new Date(updatedTime);
    const updatedDateInIST = updatedDateObj.toLocaleString("en-CA", {
      timeZone: "Asia/Kolkata",
    });
    const currentDateInIST = new Date().toLocaleDateString("en-CA", {
      timeZone: "Asia/Kolkata",
    });
    const updatedDate = updatedDateInIST.slice(0, 10);
    return currentDateInIST === updatedDate;
  };

  const isUserOnline = (updatedTime) => {
    const updatedDateObj = new Date(updatedTime);
    const currentTime = new Date();
    const differenceInMinutes = (currentTime - updatedDateObj) / (1000 * 60);
    return differenceInMinutes <= 5;
  };

  const handleChangeType = async () => {
    setParentId(LoginUser?._id);
    if (location.pathname == "/admin/admin") {
      if (
        LoginUser?.userType.toLowerCase() === "admin" &&
        LoginUser?.isSuperAdmin == 1
      ) {
        console.log("tety");
        setUserType("admin");
      }
    } else if (location.pathname == "/admin/Stokez") {
      setUserType("STO");
    } else if (location.pathname == "/admin/Agent") {
      if (LoginUser?.userType == "STO") {
        setParentId(LoginUser?._id);
      }
      if (location.state != null) {
        setParentId(location?.state?.id);
      }
      setUserType("Agent");
    } else if (location.pathname == "/admin/Player") {
      if (location?.state != null) {
        setParentId(location?.state?.id);
      }
      setUserType("Player");
    }
    if (isFromDashboard) {
      setParentId(LoginUser?._id);
    }
  };

  const GetUserList = useCallback(async () => {
    setLoading(true);
    setTotalPage(0);
    setTableData([]);
    const { data } = await PostRequest(
      API + "users/UsersList",
      {
        userType,
        page,
        pageSize,
        ParentId,
      },
      {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }
    );
    setLoading(false);

    if (data.responseCode === 1) {
      const rows = data.responseData;
      let filteredData = rows;

      if (isFromDashboard) {
        filteredData = rows.filter((user) =>
          isUpdatedTodayInIST(user.updatedAt)
        );
      }

      if (filter === "Online") {
        filteredData = filteredData.filter((user) =>
          isUserOnline(user.updatedAt)
        );
      } else if (filter === "Offline") {
        filteredData = filteredData.filter(
          (user) => !isUserOnline(user.updatedAt)
        );
      }
      // filteredData = filteredData.filter((user) => user.parentUsername.toLowerCase() === LoginUser?.username.toLowerCase());
      setTotalPage(Math.ceil(data.totalRecords / pageSize));
      setTableData(filteredData);
    } else {
      setTotalPage(0);
      setTableData([]);
      // window.location.href = "/admin/Stokez";
    }
  }, [API, PostRequest, location.pathname]);

  useEffect(() => {
    GetUserList();
  }, [location.pathname, page, pageSize, CallApi, isFromDashboard, filter]);

  useEffect(() => {
    GetUserList();
  }, [userType, ParentId]);

  useEffect(() => {
    handleChangeType();
  }, [location.pathname, isFromDashboard, filter, CallApi, page, pageSize]);

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
    setPage(1);
  };

  return (
    <div className="position-absolute">
      <PageNameBar />
      <div className="grid h-full w-full gap-x-5 gap-y-1">
        <div className="mb-3 flex gap-x-3">
          <SelectField
            extra="mb-1"
            label="Select Range"
            onChange={(e) => setPageSize(e.target.value)}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="60">60</option>
            <option value="80">80</option>
            <option value="90">90</option>
            <option value="100">100</option>
          </SelectField>
        </div>
        <div>
          <ul style={{ display: "flex", flexDirection: "row" }}>
            <li
              className={`cursor-pointer rounded-md px-4 py-2 transition-all duration-300 ${
                filter === "Offline"
                  ? "bg-blue-500 text-white hover:bg-blue-600"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
              onClick={() => handleFilterClick("Offline")}
              style={{ margin: "5px" }}
            >
              Offline
            </li>
            <li
              className={`cursor-pointer rounded-md px-4 py-2 transition-all duration-300 ${
                filter === "Online"
                  ? "bg-blue-500 text-white hover:bg-blue-600"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
              onClick={() => handleFilterClick("Online")}
              style={{ margin: "5px" }}
            >
              Online
            </li>
            <li
              className={`cursor-pointer rounded-md px-4 py-2 transition-all duration-300 ${
                filter === "All"
                  ? "bg-blue-500 text-white hover:bg-blue-600"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
              onClick={() => handleFilterClick("All")}
              style={{ margin: "5px" }}
            >
              All
            </li>
            <li
              className="cursor-pointer rounded-md bg-gray-200 px-4 py-2 text-gray-700 transition-all duration-300 hover:bg-gray-300"
              onClick={() => handleFilterClick("Complete")}
              style={{ margin: "5px" }}
            >
              Complete
            </li>
          </ul>
        </div>
        {loading ? <Loader loading={loading} /> : null}
        {TableData.length > 0 ? (
          <DevelopmentTable
            columnsData={
              location.pathname === "/admin/Player"
                ? columnsDataUsers
                : columnsDataDevelopment
            }
            tableData={TableData}
            pageSize={page}
            setCallApi={setCallApi}
            CallApi={CallApi}
          />
        ) : (
          <DevelopmentTable
            columnsData={
              location.pathname === "/admin/Player"
                ? columnsDataUsers
                : columnsDataDevelopment
            }
            tableData={[]}
            pageSize={page}
            setCallApi={setCallApi}
            CallApi={CallApi}
          />
        )}
        <Pagination totalPage={TotalPage} current={page} setPage={setPage} />
      </div>
    </div>
  );
};

export default Tables;
