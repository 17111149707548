import useHttp from "Hooks/useHttp";
import InputField from "components/fields/InputField";
import React, { useEffect, useState } from "react";
import routes from "routes";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import SelectField from "components/fields/SelectField";
const WinGame = () => {
  const [loading, setLoading] = useState(false);
  const API = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const { PostRequest } = useHttp();
  const [GameId, setGameId] = useState("");
  const [Cart, setCart] = useState("1");
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  // const [CurrentPath, setCurrentPath] = React.useState("Main Dashboard");
  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
        // setCurrentPath(routes[i].path);
      }
    }
    return activeRoute;
  };
  useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);
  const CurrentGame = async () => {
    setLoading(true);
    const FormData = {
      username: "",
    };

    const { data } = await PostRequest(API + "games/CurrentGame", FormData, {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    });
    setLoading(false);
    setGameId(data.data.game_id);
    setCart(data.data.winCard);
  };

  const Update = async () => {
    setLoading(true);
    const FormData = {
      game_id: GameId,
      Cart,
    };
    const { data } = await PostRequest(API + "games/WinGame", FormData, {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    });
    setLoading(false);
    if (data.status === "error") {
      toast.error(data.msg);
    } else {
      toast.success(data.msg);
    }
  };

  useEffect(() => {
    CurrentGame();
  }, []);

  return (
    <div className="flex">
      {/* {loading ? <Loader loading={loading} /> : null} */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="w-99p flex-initial md:w-50p">
        <div className="mb-2 flex items-center justify-center rounded-xl bg-[#30A5FF] px-4 py-4  sm:px-6">
          <h1 className="text-2xl font-black text-white">{currentRoute}</h1>
        </div>
        <div className="rounded-xl bg-white px-4 py-3  sm:px-6">
          <InputField
            variant="auth"
            extra="mb-1"
            label="Current Game Id *"
            id="name"
            type="text"
            disabled={true}
            value={GameId}
            onChange={(e) => setGameId(e.target.value)}
          />
          <SelectField
            variant="auth"
            extra="mb-1"
            label={`Select Cart *`}
            id="email"
            type="text"
            value={Cart}
            onChange={(e) => setCart(e.target.value)}
          >
            <option value="k1">K ❤️</option>
            <option value="k2">K ♠</option>
            <option value="k3">K ♦️</option>
            <option value="k4">K ♣</option>
            <option value="q1">Q ❤️</option>
            <option value="q2">Q ♠️</option>
            <option value="q3">Q ♦</option>
            <option value="q4">Q ♣</option>
            <option value="j1">J ❤️</option>
            <option value="j2">J ♠️</option>
            <option value="j3">J ♦</option>
            <option value="j4">J ♣</option>
          </SelectField>
          <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
              onClick={Update}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinGame;
