import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const MaintenanceMode = () => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const Data = sessionStorage.getItem("user");
  const [LoginUser] = useState(JSON.parse(Data));
  const navigate = useNavigate();

  useEffect(() => {
    if (isMaintenanceMode && LoginUser.isSuperAdmin == 0) {
      navigate("/MaintenanceMode");
    }
  }, [isMaintenanceMode]);

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <div className="text-center">
        <div className="mb-6 flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-16 w-16 text-yellow-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <h1 className="mb-4 text-4xl font-bold text-gray-800">
          We'll be back soon!
        </h1>
        <p className="mb-6 text-gray-600">
          Our website is currently undergoing scheduled maintenance. We should
          be back shortly. Thank you for your patience!
        </p>
        <button
          className="rounded-lg bg-blue-500 px-6 py-3 text-white hover:bg-blue-600"
          onClick={() => window.location.reload()}
        >
          Refresh
        </button>
      </div>
    </div>
  );
};

export default MaintenanceMode;
