import { width } from "@mui/system";
import React from "react";

export const columnsDataDevelopment = [
  // {
  //   Header: "ID",
  //   accessor: "_id",
  // },
  {
    Header: "USERNAME",
    accessor: "username",
  },
  {
    Header: "PASSWORD",
    accessor: "password",
  },
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "USERTYPE",
    accessor: "userType",
  },
  {
    Header: "BALANCE",
    accessor: "Balance",
  },
  {
    Header: "PARENT",
    accessor: "parent",
  },
  {
    Header: "ACTION",
    accessor: "action",
  },
];

export const columnsDataUsers = [
  // {
  //   Header: "ID",
  //   accessor: "username",
  // },
  {
    Header: "USERNAME",
    accessor: "username",
  },
  {
    Header: "PASSWORD",
    accessor: "password",
  },
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "USERTYPE",
    accessor: "userType",
  },
  {
    Header: "BALANCE",
    accessor: "Balance",
  },
  {
    Header: "PARENT",
    accessor: "parent",
  },
  {
    Header: "TOTALBET",
    accessor: "TotalBet",
  },
  {
    Header: "TOTALWON",
    accessor: "TotalWon",
  },
  {
    Header: "CASINOPROFIT",
    accessor: "CasinoProfit",
  },
  {
    Header: "ACTION",
    accessor: "action",
  },
];

export const columnsTurnoverReport = [
  {
    headerName: (
      <span style={{ color: "#30A5FF", fontWeight: "bold" }}>USER NAME</span>
    ),
    field: "userId",
    width: 150,
    sortable: false,
  },
  {
    headerName: (
      <span style={{ color: "#30A5FF", fontWeight: "bold" }}>NAME</span>
    ),
    field: "NAME",
    width: 90,
    sortable: false,
  },
  {
    headerName: (
      <span style={{ color: "#30A5FF", fontWeight: "bold" }}>PLAY POINT</span>
    ),
    field: "PlayPoint",
    width: 110,
    sortable: false,
  },
  {
    headerName: (
      <span style={{ color: "#30A5FF", fontWeight: "bold" }}>WIN POINT</span>
    ),
    field: "WinPoint",
    width: 110,
    sortable: false,
  },
  {
    headerName: (
      <span style={{ color: "#30A5FF", fontWeight: "bold" }}>END POINT</span>
    ),
    field: "TotalEndPoint",
    width: 110,
    sortable: false,
  },
  {
    headerName: (
      <span style={{ color: "#30A5FF", fontWeight: "bold" }}>CLAIM POINT</span>
    ),
    field: "Claimpoint",
    width: 120,
    sortable: false,
  },
  {
    headerName: (
      <span style={{ color: "#30A5FF", fontWeight: "bold" }}>
        UNCLAIM POINT
      </span>
    ),
    field: "UNClaimpoint",
    width: 140,
    sortable: false,
  },
  {
    headerName: (
      <span style={{ color: "#30A5FF", fontWeight: "bold" }}>Agent Point</span>
    ),
    field: "AgentPoint",
    width: 110,
    sortable: false,
  },
  {
    headerName: (
      <span style={{ color: "#30A5FF", fontWeight: "bold" }}>NET</span>
    ),
    field: "Net",
    width: 100,
    sortable: false,
  },
  {
    headerName: (
      <span style={{ color: "#30A5FF", fontWeight: "bold" }}>NTP %</span>
    ),
    field: "NTP",
    width: 70,
    sortable: false,
  },
  {
    headerName: (
      <span style={{ color: "#30A5FF", fontWeight: "bold" }}>Revenue %</span>
    ),
    field: "Revenue",
    width: 100,
    sortable: false,
  },
  // {  this data remove date 06/09/2024 by raj
  //   headerName: (
  //     <span style={{ color: "#30A5FF", fontWeight: "bold" }}>TYPE</span>
  //   ),
  //   field: "Type",
  //   width: 80,
  //   sortable: false,
  // },
];

export const columnsPlayerHistory = [
  // {
  //   headerName: "ID",
  //   field: "_id",
  //   width: 30,
  //   sortable: false,
  // },
  {
    headerName: "USERNAME",
    field: "userId",
    width: 100,
  },
  {
    headerName: "GAME",
    field: "gameId",
    width: 90,
  },
  {
    headerName: "TICKETTIME",
    field: "ticketTime",
    width: 180,
  },
  {
    headerName: "STARTPOINT",
    field: "StartPoint",
    width: 120,
  },
  {
    headerName: "BET",
    field: "Bet",
    width: 90,
  },
  {
    headerName: "WON",
    field: "Won",
    width: 90,
  },
  {
    headerName: "ENDPOINT",
    field: "EndPoint",
    width: 90,
  },
  {
    headerName: "CLAIMPOINT",
    field: "Claimpoint",
    width: 110,
  },
  {
    headerName: "UNCLAIMPOINT",
    field: "UNClaimpoint",
    width: 110,
  },
  {
    headerName: "CANCELLED",
    field: "Cancelled",
    width: 110,
  },
  {
    headerName: "BONUSSPIN",
    field: "BonusSpin",
    width: 100,
  },
];
const renderLastNameCell = (params) => {
  const columnName = params.field;
  if (columnName === "GAMENAME") {
    return <span>Triple Chance</span>;
  }
  if (columnName === "BonusSpin") {
    return <span>0</span>;
  }
  return params.value;
};
export const columnsGameHistory = [
  {
    headerName: "ID",
    field: "id",
    width: 90,
  },
  {
    headerName: "DATETIME",
    field: "createdAt",
    width: 230,
  },
  {
    headerName: "GAMEID",
    field: "gameId",
    width: 130,
  },
  {
    headerName: "GAMENAME",
    field: "GAMENAME",
    width: 150,
    renderCell: (params) => renderLastNameCell(params),
  },
  {
    headerName: "WINPOSITION",
    field: "winCard",
    width: 170,
  },
  {
    headerName: "DETAILS",
    field: "Details",
    width: 180,
  },
  {
    headerName: "BONUSSPIN",
    field: "BonusSpin",
    width: 170,
    renderCell: (params) => renderLastNameCell(params),
  },
];

export const columnsTransactionReport = [
  {
    headerName: "TRANSACTIONID",
    field: "TransitionId",
  },
  {
    headerName: "TRANSACTIONWITH",
    field: "userId",
  },
  {
    headerName: "OLDPOINT",
    field: "OldPoint",
  },
  {
    headerName: "OUT",
    field: "Out",
  },
  {
    headerName: "IN",
    field: "In",
  },
  {
    headerName: "NEWPOINT",
    field: "New",
  },
  {
    headerName: "REFERENCE",
    field: "Reference",
  },
  {
    headerName: "TRANSFERBY",
    field: "TransferBy",
  },
  {
    headerName: "DATE",
    field: "createdAt",
    width: 130,
  },
];
