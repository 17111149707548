// import CheckTable from "./components/CheckTable";
import React from "react";
import { columnsGameHistory } from "./variables/columnsData";
import { useEffect, useState, useCallback } from "react";
import useHttp from "../../../Hooks/useHttp";
import { useLocation } from "react-router-dom";
import Pagination from "components/Pagination/Pagination";
import SelectField from "components/fields/SelectField";
import GameHistory from "./components/GameHistory";
import Loader from "components/Loader";
import InputField from "components/fields/InputField";
import routes from "routes";

const GameHistoryTables = () => {
  const [loading, setLoading] = useState(false);
  const { PostRequest } = useHttp();
  const [TableData, setTableData] = useState([]);
  const location = useLocation();
  const [FromDate, setFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [ToDate, setToDate] = useState(new Date().toISOString().split("T")[0]);
  const API = process.env.REACT_APP_API_URL;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalPage, setTotalPage] = useState(0);
  const GetUserList = useCallback(async () => {
    setLoading(true);

    const { data } = await PostRequest(
      API + "games/GetGamesAll",
      {
        page,
        pageSize,
        startDate: FromDate,
        endDate: ToDate,
      },
      {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }
    );
    setLoading(false);
    if (data.responseCode === 1) {
      console.log(data.totalPage);
      setTotalPage(Math.ceil(data.totalPage / pageSize));
      setTableData(data.responseData);
    }
  }, [API, page, pageSize, FromDate, ToDate]);
  useEffect(() => {
    GetUserList();
  }, [GetUserList, location.pathname]);

  const [currentRoute, setCurrentRoute] = useState("");
  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes?.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div className="rounded-[12px] bg-white p-[10px] sm-max:overflow-hidden ">
      <div className="top-[220px] bg-white" style={{ zIndex: 100 }}>
        <div
          className="top-[239px] mb-5  flex items-center justify-between bg-white pt-[20px]"
          style={{ zIndex: 100 }}
        >
          <div className="w-full bg-blue-500 p-2 text-xl font-bold text-white">
            {currentRoute}
          </div>
        </div>
        {/* {loading ? <Loader loading={loading} /> : null} */}
        <div
          className="top-[300px] mb-3  flex gap-x-3 bg-white sm-max:block  sm-max:overflow-x-scroll"
          style={{ zIndex: 100 }}
        >
          <SelectField
            className="md-max:w-[83px]"
            extra="mb-1"
            label="Select Range"
            onChange={(e) => setPageSize(e.target.value)}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="60">60</option>
            <option value="80">80</option>
            <option value="90">90</option>
            <option value="100">100</option>
          </SelectField>
          <SelectField
            className="w-[130px]"
            disabled={true}
            variant="auth"
            extra="mb-1"
            label="Game Name *"
          >
            <option value="">Triple Chance</option>
          </SelectField>
          <InputField
            variant="auth"
            extra="mb-1"
            label="From *"
            placeholder="Username"
            id="Username"
            value={FromDate}
            type="date"
            onChange={(e) => setFromDate(e.target.value)}
          />
          <InputField
            variant="auth"
            extra="mb-1"
            label="To *"
            placeholder="Username"
            id="Username"
            type="date"
            value={ToDate}
            onChange={(e) => setToDate(e.target.value)}
          />
          <div className="flex gap-5 sm-max:flex">
            <button
              onClick={GetUserList}
              type="button"
              className="mt-7 flex h-12 w-fit items-center justify-center rounded-xl border bg-indigo-700 p-3 text-sm text-white outline-none sm-max:w-50p"
            >
              Search
            </button>
            <button
              type="button"
              className="mt-7 flex h-12 w-fit items-center justify-center rounded-xl border bg-indigo-700  p-3 text-sm text-white outline-none sm-max:w-50p"
              onClick={handleReload}
            >
              Reload Page
            </button>
          </div>
        </div>
      </div>

      <div
        className="sticky top-[340px]  mt-5 grid h-full grid-cols-1 gap-5  md:grid-cols-1"
        style={{ zIndex: 0 }}
      >
        <GameHistory
          columnsData={columnsGameHistory}
          tableData={TableData && TableData}
          pageSize={pageSize}
        />

        {TotalPage > 0 ? (
          <Pagination totalPage={TotalPage} current={page} setPage={setPage} />
        ) : null}
      </div>
    </div>
  );
};

export default GameHistoryTables;
