import useHttp from "Hooks/useHttp";
// import AutoLogout from "components/AutoLogout";
import Clock from "components/Clock/Clock";
import React, { useEffect, useState } from "react";
import { HiMenu } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
const API = process.env.REACT_APP_API_URL;
const Navbar = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isDashboard = location.pathname === "/admin/dashboard";
  const Data = sessionStorage.getItem("user");
  const [LoginUser] = useState(JSON.parse(Data));
  useEffect(() => {
    if (!LoginUser) {
      navigate("/login");
    }
  }, []);
  const [SummeryData, setSummeryData] = useState({});
  const { PostRequest } = useHttp();
  const [show, setShow] = useState(true);
  const getTodaySummery = async () => {
    var STO = "";
    var Agent = "";
    var Player = "";
    if (LoginUser?.userType === "STO") {
      STO = LoginUser?._id;
    }
    if (LoginUser?.userType === "Agent") {
      Agent = LoginUser?._id;
    }
    if (LoginUser?.userType === "Player") {
      Player = LoginUser?._id;
    }
    const userId = sessionStorage.getItem("user");
    const test = userId ? JSON.parse(userId) : "";
    const user_id = test._id;
    const FormData = {
      STO,
      Agent,
      Player,
      userId: user_id,
    };
    const { data } = await PostRequest(API + "bets/TodaySummery", FormData, {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    });
    setSummeryData(data.responseData);
  };
  useEffect(() => {
    getTodaySummery();
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 500 ? setShow(false) : setShow(true)
    );
  }, []);
  return (
    <nav className="top-[61px] items-center justify-between gap-x-5 rounded-xl bg-[#E9ECF2] px-2  dark:bg-[#0b143797]">
      <div className="lg:w-100p xl:w-100p relative mt-[3px] h-auto  gap-2 rounded-lg bg-[transperant]  px-2 py-2   dark:!bg-navy-800  md:block  md:w-[100%] md:gap-3 lg:flex xl:gap-1">
        <div className=" md:w-100p mb-2 flex w-full items-baseline justify-between rounded-lg  bg-[transperant]  px-2 py-2 text-center   dark:!bg-navy-800  sm-max:text-xs md:mb-0 md:flex-grow-0 xl:w-30p xl:gap-2">
          {/* <HiMenu onClick={() => setOpen(!open)} size={30} /> */}
          <Clock show={show} />
        </div>

        <div className=" md:w-100p w-full rounded-lg border bg-header-headbg  px-2 py-2 shadow-xl shadow-shadow-500   dark:!bg-navy-800  dark:shadow-none sm-max:text-xs md:flex-grow-0 xl:w-90p xl:gap-2">
          <div className="flex w-full">
            <h1 className="w-20p text-lg text-white dark:hover:text-white">
              Today Summery
            </h1>
            <div className="flex w-80p flex-row-reverse gap-x-2">
              {/* <h1 className="mr-[25px] rounded bg-header-headbtn p-[10px] text-white dark:hover:text-white">
                <AutoLogout />
              </h1> */}
              {/* <Link to={isDashboard ? "/admin/Player" : "/admin/dashboard"} state={{ fromDashboard: isDashboard }}> */}
              <Link to="/admin/Player" state={{ fromDashboard: true }}>
                <h1 className="mr-[25px] rounded bg-header-headbtn p-[10px] text-white dark:hover:text-white">
                  View Dashboard
                </h1>
              </Link>
              <Link to="/admin/turnover-report">
                <h1 className="mr-[25px] rounded bg-header-headbtn p-[10px] text-white dark:hover:text-white">
                  Turnover Report
                </h1>
              </Link>
            </div>
          </div>
          <div
            className="
                text-md custom-scroll-bar scroll-content mt-3 flex  w-full gap-10 overflow-x-scroll
            sm-max:gap-3  "
          >
            <h1 className="text-xl text-header-headplaypoint dark:text-white dark:hover:text-white">
              {SummeryData.PlayPoint}
              <br />
              Play Point
            </h1>
            <h1 className="btn text-xl text-header-headwin dark:text-white dark:hover:text-white">
              {SummeryData.WinPoint}
              <br />
              Win Point
            </h1>
            <h1 className="btn text-xl text-white dark:text-white dark:hover:text-white ">
              {SummeryData.PlayPoint - SummeryData.WinPoint}
              <br />
              End Point
            </h1>
            <h1 className="btn text-xl text-header-headonlineplayer dark:text-white dark:hover:text-white">
              {SummeryData.OnlinePlayers}
              <br />
              Online Players
            </h1>
            <h1 className="btn text-xl text-header-headavgbet  dark:text-white dark:hover:text-white">
              {SummeryData.AverageBet?.toFixed(2)}
              <br />
              Average Bet
            </h1>
            <h1 className="btn text-xl text-header-headtotalplayer dark:text-white dark:hover:text-white">
              {SummeryData.TotalPlayers}
              <br />
              Total Players
            </h1>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
