/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";

import routes from "routes.js";
import { BsAppIndicator } from "react-icons/bs";
import { useEffect, useState } from "react";
import userImg from "../../assets/img/sidebar/user.webp";

const Sidebar = ({ open, onClose }) => {
  const Data = sessionStorage.getItem("user");
  // const [LoginUser, setLoginUser] = useState(JSON.parse(Data));
  const [route] = useState(routes);
  const [LoginUser, setLoginUser] = useState("");

  // Update loginUserData when sessionStorage changes
  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    setLoginUser(userData);
  }, [route]);
  // `mx-[12px] h-full flex-none transition-all md:pr-2
  return (
    <div
      className={`sm:none  duration-175 linear !z-50 m-0 flex h-[100vh]  min-h-full w-1/5 flex-col bg-[#222222] pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <div className=" justify-left flex h-[90px] items-center bg-[#1b3548]">
        <img className="ml-[20px] h-[50px] w-[50px]" src={userImg} alt="" />
        <div className="justify-starttext-left ml-[10px] flex flex-col  items-center">
          <span className="text-[20px] text-[#30a5ff]">
            {LoginUser ? LoginUser?.name : ""}
          </span>
          <div className="flex items-center">
            <span className="mr-[5px] h-[13px] w-[13px] rounded-[50%] bg-[#8AD919]"></span>
            <span className=" text-white">Online</span>
          </div>
        </div>
      </div>
      <div className="mb-0 mt-[1px] h-px  bg-gray-300 dark:bg-white/30" />
      <div className="bg-nav pb-56">
        <ul className="pt-[18px]  !text-white" style={{ height: "37rem" }}>
          <Links
            routes={route.filter((prop) => {
              const {
                userType,
                isSuperAdmin = 0,
                security = 0,
              } = LoginUser || {};
              const typeConditions = {
                admin:
                  isSuperAdmin == 1
                    ? [0, 1, 2, 3, 4]
                    : security == 1
                    ? [0, 1, 2, 3]
                    : [1, 2, 3],
                STO: security == 1 ? [0, 2, 3] : [2, 3],
                Agent: security == 1 ? [0, 3] : [3],
                Player: security == 1 ? [0, 3] : [3],
              };
              return typeConditions[userType]?.includes(prop.type);
            })}
          />
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
