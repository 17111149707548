import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { ToastContainer, toast } from "react-toastify";

// Mapping of card values to their symbols
const cardSymbols = {
  k1: "K ❤️",
  k2: "K ♠",
  k3: "K ♦️",
  k4: "K ♣",
  q1: "Q ❤️",
  q2: "Q ♠️",
  q3: "Q ♦",
  q4: "Q ♣",
  j1: "J ❤️",
  j2: "J ♠️",
  j3: "J ♦",
  j4: "J ♣",
};

const GraphComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  const [gameId, setGameId] = useState(null);

  // Extract userId and userType from the URL query parameters
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const userType = searchParams.get("userType");
  const API = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(API + "bets/getCurrentGameForGraph", {
          username: userId,
          userType,
        });
        const { cardSums, details } = response.data;

        // Set the gameId from the first detail object
        if (details.length > 0) {
          setGameId(details[0].gameId);
        }

        // Prepare data for the chart, mapping symbols
        const chartData = Object.entries(cardSums).map(([key, value]) => ({
          name: cardSymbols[key] || key, // Use mapped symbol or key if not found
          value,
        }));

        setData(chartData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, userType]);

  const handleCheckboxChange = async (name) => {
    if (!gameId) {
      toast.error("Game ID is not available.");
      return;
    }

    // Get the original key from the symbol
    const cardKey = Object.keys(cardSymbols).find(
      (key) => cardSymbols[key] === name
    );
    if (!cardKey) {
      toast.error("Card symbol not found.");
      return;
    }

    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));

    try {
      const response = await axios.post(API + "games/WinGame", {
        game_id: gameId,
        Cart: cardKey, // Send the original key as the Cart value
      });
      const { data } = response;
      if (data.status === "error") {
        toast.error(data.msg);
      } else {
        toast.success(data.msg);
      }
    } catch (err) {
      toast.error("Update failed.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h2>Card Values Bar Graph</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "20px",
          marginLeft: "-10px",
        }}
      >
        {data.map((entry) => (
          <div
            key={entry.name}
            style={{ textAlign: "center", marginRight: "20px" }}
          >
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                fontSize: "30px",
              }}
            >
              <input
                type="checkbox"
                checked={checkedItems[entry.name] || false}
                onChange={() => handleCheckboxChange(entry.name)}
                style={{ transform: "scale(1.5)" }} // Adjust the scale factor to increase/decrease size
              />
              {entry.name}
            </label>
          </div>
        ))}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default GraphComponent;
