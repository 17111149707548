import React, { useCallback, useEffect, useState } from "react";
import home from "../../assets/img/topbar/Home.jpg";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useHttp from "Hooks/useHttp";
const TopBar = () => {
  const { PostRequest } = useHttp();
  const API = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [Balance, setBalance] = useState(0);
  const Data = sessionStorage.getItem("user");
  const [LoginUser] = useState(JSON.parse(Data));
  const token = sessionStorage.getItem("token");
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  const getControlPanels = async () => {
    const { data } = await PostRequest(
      API + "control/getControlPanels",
      {},
      {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }
    );
    if (data?.responseCode === 1) {
      setIsMaintenanceMode(
        data?.responseData?.maintenanceMode == 1 ? true : false
      );
    } else {
      toast.error(data?.responseText);
    }
  };

  useEffect(() => {
    if (isMaintenanceMode) {
      navigate("/MaintenanceMode");
    }
  }, [isMaintenanceMode]);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token, LoginUser]);

  useEffect(() => {
    getControlPanels();
  }, []);

  const handleClick = useCallback(() => {
    sessionStorage.clear();
    navigate("/login");
    toast.success("Logout successful");
  }, [navigate]);

  const FetchUserDetails = async () => {
    setBalance(0);
    const { data } = await PostRequest(
      API + "users/userDetails",
      { userId: LoginUser?._id },
      {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }
    );
    if (data.responseCode === 1) {
      console.log(data.responseData);
      sessionStorage.setItem("user", JSON.stringify(data.responseData));
      setBalance(data.responseData.Balance);
    } else {
      toast.error(data.responseText);
    }
  };

  useEffect(() => {
    FetchUserDetails();
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        className=" top-0 flex h-[60px] w-full items-center justify-between bg-nav "
        style={{ zIndex: 9999 }}
      >
        <div className=" ml-3 flex items-center justify-center">
          <img
            src="../assets/logo.png"
            style={{ width: 94, height: 65 }}
            alt="menu"
            className="h-6 w-6"
          />
          <span className="font-lg p-1 text-[21px] text-navlogo">
            JEETOGAMEZ
          </span>
          <span className="font-lg text-[21px] text-white">Admin</span>
        </div>

        <div className="flex items-center ">
          <div className="mr-[20px] flex h-[40px] items-center rounded-[5px] bg-[#393939] px-[10px] py-[5px] text-navlogo">
            <span>Balance: </span>
            <span className="ml-[5px]">{Balance}</span>
          </div>
          <img className="mr-[40px]" src={home} alt="" />
          <button
            onClick={() => handleClick()}
            className="mr-[40px] flex h-[32px] items-center rounded-[5px] bg-[#444444] p-[10px] text-white"
          >
            logout
          </button>
        </div>
      </div>
    </>
  );
};

export default TopBar;
