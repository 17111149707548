import React from "react";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";
import Widget from "components/widget/Widget";
import { useCallback, useEffect, useState } from "react";
import useHttp from "Hooks/useHttp";
import { useNavigate } from "react-router-dom";
import PageNameBar from "components/PageNameBar/PageNameBar";
import LineChart from "components/charts/LineChart";
import DashboardChart from "components/charts/DashboardChart";
const API = process.env.REACT_APP_API_URL;
const Dashboard = () => {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);
  const Data = sessionStorage.getItem("user");
  const [LoginUser] = useState(JSON.parse(Data));
  const [SummeryData, setSummeryData] = useState({});
  const { PostRequest } = useHttp();
  const getTodaySummery = useCallback(async () => {
    var STO = "";
    var Agent = "";
    var Player = "";
    if (LoginUser?.userType === "STO") {
      STO = LoginUser?._id;
    }
    if (LoginUser?.userType === "Agent") {
      Agent = LoginUser?._id;
    }
    if (LoginUser?.userType === "Player") {
      Player = LoginUser?._id;
    }
    const userId = sessionStorage.getItem("user");
    const test = userId ? JSON.parse(userId) : "";
    const user_id = test._id;
    const FormData = {
      STO,
      Agent,
      Player,
      userId: user_id,
    };
    const { data } = await PostRequest(API + "bets/TodaySummery", FormData, {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    });
    setSummeryData(data.responseData);
  }, [LoginUser?._id, LoginUser?.userType]);
  useEffect(() => {
    if (token) {
      getTodaySummery();
    }
  }, [getTodaySummery]);
  return (
    <div>
      <PageNameBar />
      <DashboardChart />
      {/* <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Play Point"}
          subtitle={SummeryData.PlayPoint}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Win Point"}
          subtitle={SummeryData.WinPoint}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"End Point"}
          subtitle={SummeryData.PlayPoint - SummeryData.WinPoint}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Online Players"}
          subtitle={SummeryData.OnlinePlayers}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Average Bet"}
          subtitle={SummeryData.AverageBet}
        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Total Players"}
          subtitle={SummeryData.TotalPlayers}
        />
      </div> */}
    </div>
  );
};

export default Dashboard;
