import useHttp from "Hooks/useHttp";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import React, { useEffect, useState } from "react";
import routes from "routes";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
const AdjustPoint = () => {
  const [loading, setLoading] = useState(false);
  const Data = sessionStorage.getItem("user");
  const LoginUser = JSON.parse(Data);
  const location = useLocation();
  const API = process.env.REACT_APP_API_URL;
  const { PostRequest } = useHttp();
  const [Users, setUsers] = useState([]);
  const [userId, setUserId] = useState([]);
  const [option, setOption] = useState([]);
  const [Balance, setBalance] = useState(0);
  const [AmountToTransfer, setAmountToTransfer] = useState("");
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);
  const Update = async () => {
    if (LoginUser?.AccountStatus === 3) {
      toast.error("Access denied! Please contact Admin.");
      return false;
    }
    if (Balance < parseInt(AmountToTransfer)) {
      toast.error("Low Balance! Please Recharge Your wallet ");
      return false;
    }
    setLoading(true);
    const FormData = {
      userId: userId,
      Balance: AmountToTransfer,
      TransferBy: LoginUser?._id,
    };
    try {
      setLoading(true);
      const { data } = await PostRequest(
        API + "users/DeductBalance",
        FormData,
        {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        }
      );
      if (data.responseCode === 1) {
        FetchUserDetails(userId);
        toast.success(data.responseText);
      } else {
        toast.error(data.responseText);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };
  const CheckUserName = async (selectUserType) => {
    setLoading(true);
    const { data } = await PostRequest(
      API + "users/GetUsers",
      {
        loginUserType: LoginUser?.userType,
        selectUserType: selectUserType,
        userId: LoginUser?._id,
      },
      {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }
    );
    setLoading(false);
    if (data?.responseCode === 1) {
      setUsers(data.responseData);
    } else {
      setUsers([]);
      toast.error(data?.responseText);
    }
  };

  const handleChangeType = (e) => {
    CheckUserName(e.target.value);
  };
  const handleChangeUser = (e) => {
    FetchUserDetails(e.target.value);
    setUserId(e.target.value);
  };

  const FetchUserDetails = async (ID) => {
    setBalance(0);
    const { data } = await PostRequest(
      API + "users/userDetails",
      { userId: ID },
      {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }
    );
    if (data.responseCode === 1) {
      setBalance(data.responseData.Balance);
    } else {
      toast.error(data.responseText);
    }
  };
  useEffect(() => {
    if (LoginUser?.userType === "admin") {
      setOption(["STO", "Agent", "Player"]);
    } else if (LoginUser?.userType === "STO") {
      setOption(["Agent", "Player"]);
    } else if (LoginUser?.userType === "Agent") {
      setOption(["Player"]);
    }
  }, []);

  return (
    <div className="flex">
      {/* {loading ? <Loader loading={loading} /> : null} */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="w-99p flex-initial md:w-50p">
        <div className="mb-2 flex items-center justify-center rounded-xl bg-[#30A5FF] px-4 py-4  sm:px-6">
          <h1 className="text-2xl font-black text-white">{currentRoute}</h1>
        </div>
        <div className="rounded-xl bg-white px-4 py-3  sm:px-6">
          <SelectField
            variant="auth"
            extra="mb-1"
            label={`Select User Type *`}
            id="email"
            type="text"
            onChange={handleChangeType}
          >
            <option value="">Select User Type</option>
            {option.map((name) => {
              return (
                <option value={name} key={name}>
                  {name}
                </option>
              );
            })}
          </SelectField>
          <SelectField
            variant="auth"
            extra="mb-1"
            label={`Select User Type *`}
            id="email"
            type="text"
            onChange={handleChangeUser}
          >
            <option value="">Select User</option>
            {Users.map((user, index) => {
              return (
                <option value={user._id} key={index}>
                  {user.username}
                </option>
              );
            })}
          </SelectField>
          <InputField
            variant="auth"
            extra="mb-1"
            label="Balance *"
            placeholder="Name"
            id="name"
            type="text"
            disabled={true}
            value={Balance}
          />
          <InputField
            variant="auth"
            extra="mb-1"
            label="Amount To Transfer *"
            placeholder="51000"
            id="password"
            type="text"
            value={AmountToTransfer}
            onChange={(e) => setAmountToTransfer(e.target.value)}
          />
        </div>
        <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          {loading ? (
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            >
              Updating please wait..
            </button>
          ) : (
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
              onClick={Update}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdjustPoint;
