import React, { useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import { useNavigate } from "react-router-dom";
import TopBar from "components/Topbar/TopBar";
export default function Admin(props) {
  const navigate = useNavigate();
  const { ...rest } = props;
  const location = useLocation();
  const token = sessionStorage.getItem("token");
  const Data = sessionStorage.getItem("user");
  const [LoginUser] = useState(JSON.parse(Data));
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 700 ? setOpen(false) : setOpen(true)
    );
  }, [LoginUser]);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  React.useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token, LoginUser]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes, LoginUser) => {
    const { userType, isSuperAdmin } = LoginUser || {};
    const typeConditions = {
      admin: isSuperAdmin == 1 ? [0, 1, 2, 3, 4] : [0, 1, 2, 3],
      STO: [0, 2, 3],
      Agent: [0, 3],
    };
    const filteredRoutes = routes.filter((prop) =>
      typeConditions[userType]?.includes(prop.type)
    );
    return filteredRoutes.map((prop, key) => (
      <Route path={`/${prop.path}`} element={prop.component} key={key} />
    ));
  };

  document.documentElement.dir = "ltr";
  return (
    <>
      <TopBar />
      <div className="z-10 flex h-full w-full">
        <Sidebar open={open} onClose={() => setOpen(false)} />
        {/* Navbar & Main Content */}
        <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
          {/* Main Content */}
          <main className={` h-full flex-none transition-all `}>
            {/* Routes */}
            <div className="h-full ">
              <Navbar
                onOpenSidenav={() => setOpen(true)}
                logoText={"JEETO GAMEZ"}
                brandText={currentRoute}
                secondary={getActiveNavbar(routes)}
                open={open}
                setOpen={setOpen}
                {...rest}
              />
              <div className="mt mx-auto mb-auto h-full w-full p-2 pt-10 md:mt-1 md:pr-2">
                <Routes>
                  {getRoutes(routes, LoginUser)}
                  <Route
                    path="/"
                    element={<Navigate to="/admin/dashboard" replace />}
                  />
                </Routes>
              </div>
              <div className="p-3">
                <Footer />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
